import { registerFunctionComponent } from '/js/lib/webact.js';
import { stringToElements } from '../../helpers.js';

function HorizontalList ({ hydrate }) {
  const { html, css, postRender, $ } = this;

  css`
    :host {
      height: 179.86px;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      overflow-x: scroll;
      scroll-snap-type: mandatory;
      scroll-snap-points-y: repeat(128px);
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      align-items: center;
      will-change: scroll-position;
    }

    ::slotted(*) {
      scroll-snap-align: start;
    }

    ::slotted(:not(:first-child)) {
      margin-left: calc(var(--gr-rem) / 2);
    }
  `;

  html`
    <slot></slot>
  `;

  postRender(() => {
    const self = $();

    if (hydrate) {
      self.innerHTML = 'Loading...';

      const io = new IntersectionObserver(entries => {
        entries.forEach(async entry => {
          if (entry.isIntersecting) {
            const response = await fetch(hydrate + '&partialContent=true');
            const content = await response.text();
            const newList = stringToElements(content).querySelector('horizontal-list');

            if (newList.children.length > 0) {
              self.parentNode.replaceChild(newList, self);
            } else {
              self.previousSibling.remove();
              self.remove();
            }

            io.disconnect();
          }
        });
      });

      io.observe(self);
    }
  });
}

registerFunctionComponent(HorizontalList, {
  name: 'horizontal-list'
});
